export const menuData = [
  {
    heading: "What we do",
    dropdown: [
      {
        title: "Digitalisation",
        content: [
          {
            title: "Application Development",
            url: "/services/application-development",
            descr: "",
            links: [
              {
                text: "Web Application Development",
                link: "/services/web-app-development",
              },
              {
                text: "Native Mobile Application Development",
                link: "/services/mobile-app-development",
              },
              {
                text: "Hybrid App Development",
                link: "/services/hybrid-app-development",
              },
              {
                text: "PWA Development",
                link: "/services/pwa-development",
              },
            ],
          },
          {
            title: "Product Engineering",
            url: "/services/Product-engineering",
            descr: "",
            links: [
              {
                text: "Custom Product Engineering",
                link: "/services/custom-product-engineering",
              },
              {
                text: "Product Rescue",
                link: "/services/product-rescue",
              },
              {
                text: "Outsourced Product Development",
                link: "/services/outsourced-product-development",
              },
              {
                text: "Legacy Software Modernization",
                link: "/services/legacy-software-modernization",
              },
            ],
          },
          {
            title: "IT Staff Augmentation",
            // title: "Team Augmentation",
            url: "/services/staff-augmentation",
            descr: "",
            links: [
              {
                // text: "Staff Augmentation",
                // link: "/services/team-staff-augmentation",
                text: "Full Stack Team Augmentation",
                link:"/services/staff-augmentation/?slug=9#offerings",
                section:true,
              },
              {
                // text: "Dedicated Team Augmentation",
                // link: "/services/dedicated-team-augmentation",
                text: "Specialized Skill Augmentation",
                link:"/services/staff-augmentation/?slug=10#offerings",
                section:true
              },
              {
                // text: "Technical Expertise Provision",
                // link: "/services/technical-expertise-provision",
                text: "Hybrid Team Augmentation",
                link:"/services/staff-augmentation/?slug=11#offerings",
                section:true
              },
            ],
          },
          {
            title: "Enterprise Solution Integration",
            url: "/services/enterprise-solution-integration",
            descr: "",
            links: [
              {
                text: "Oracle APEX",
                link: "/services/oracle-apex-application-development",
              },
              {
                text: "NetSuite",
                link: "/services/netsuite-development",
              },
              {
                text: "Salesforce",
                link: "/services/salesforce-development",
              },
              {
                text: "Microsoft Dynamics",
                link: "/services/microsoft-dynamics",
              },
            ],
          },
        ],
      },
      {
        title: "Data & AI",
        content: [
          {
            title: "Artificial Intelligence",
            url: "/services/artificial-intelligence",
            descr: "",
            links: [
              {
                text: "Generative AI",
                link: "/services/generative-AI",
              },
              {
                text: "Conversational AI",
                link: "/services/conversational-artificial-intelligence",
              },
              {
                text: "Open GPT",
                link: "/services/open-GPT",
              },
              {
                text: "Vision Analytics",
                link: "/services/vision-analytics",
              },
              {
                text: "Computer Vision",
                link: "/services/computer-vision",
              },
            ],
          },
          {
            title: "Machine Learning",
            url: "/services/machine-learning",
            descr: "",
            links: [
              {
                text: "ML Model Development",
                link: "/services/ML-model-development-and-training",
              },
              {
                text: "Predictive Analysis",
                link: "/services/predictive-analysis",
              },
              {
                text: "Natural Language Processing",
                link: "/services/natural-language-processing",
              },
              {
                text: "Deep Learning Development",
                link: "/services/deep-learning-development",
              },
              {
                text: "MLOps Consulting",
                link: "/services/MLOps-consulting-services",
              },
            ],
          },
          {
            title: "Data Analytics",
            url: "/services/data-analytics",
            descr: "",
            links: [
              {
                text: "Data Analysis as a Service",
                link: "/services/data-analysis-as-a-service",
              },
              {
                text: "Data Visualisation",
                link: "/services/data-visualisation",
              },
              {
                text: "Power BI",
                link: "/services/Power-BI",
              },
              {
                text: "BI Dashboard",
                link: "/services/BI-dashboard",
              },
              {
                text: "Automated Reporting",
                link: "/services/automated-reporting",
              },
            ],
          },
          {
            title: "RPA",
            url: "/services/robotic-process-automation",
            descr: "",
            links: [
              {
                text: "Process Assessment and Consultation",
                link: "/services/process-assessment-and-consultation",
              },
              {
                text: "Development and Implementation",
                link: "/services/development-and-implementation",
              },
              {
                text: "RPA Process Optimization",
                link: "/services/RPA-process-optimization",
              },
            ],
          },
        ],
      },
      {
        title: "Cloud",
        content: [
          {
            title: "Cloud Transformation",
            url: "/services/cloud-transformation-services",
            descr: "",
            links: [
              {
                text: "Cloud Architecture and Design",
                link: "/services/architecture-and-design",
              },
              {
                text: "Cloud Migration",
                link: "/services/cloud-migration-and-integration",
              },
              {
                text: "Cloud-Native Development",
                link: "/services/cloud-native-development",
              },
              {
                text: "Cloud Integration",
                link: "/services/cloud-integration-services",
              },
              {
                text: "Cloud Data & AI",
                link: "/services/cloud-data-and-AI/",
              },
            ],
          },
          {
            title: "DevOps As A Service",
            url: "/services/devOps-as-a-service",
            descr: "",
            links: [
              {
                text: "DevOps Assessment",
                link: "/services/devOps-assessment",
              },
              {
                text: "DevOps Automation",
                link: "/services/devOps-automation",
              },
              // {
              //   text: "DevOps Management",
              //   link: "/services/DevOps-management",
              // },
              {
                text: "CI/CD",
                link: "/services/continuous-integration-continuous-deployment",
              },
            ],
          },
          {
            title: "Maintenance",
            url: "/services/cloud-maintenance",
            descr: "",
            links: [
              {
                text: "Managed Support",
                link: "/services/managed-support",
              },
              {
                text: "Pay-As-You-Go Support",
                link: "/services/pay-as-you-go-support",
              },
              {
                text: "Emergency Support",
                link: "/services/emergency-support",
              },
            ],
          },
          {
            title: "Cloud Services",
            url: "/services/cloud-services",
            descr: "",
            links: [
              {
                text: "Kubernetes",
                link: "/services/Kubernetes",
              },
              {
                text: "Docker SWARM",
                link: "/services/Docker-SWARM",
              },
              {
                text: "AWS",
                link: "/services/aws",
                single: true,
              },
              {
                text: "Azure",
                link: "/services/azure",
                single: true,
              },
              {
                text: "GCP",
                link: "/services/GCP",
                single: true,
              },
            ],
          },
        ],
      },
      {
        title: "Consulting",
        content: [
          {
            title: "Digital Transformation",
            url: "/services/digital-transformation-consulting",
            descr: "",
            links: [
              {
                text: "Agile Transformation",
                link: "/services/agile-transformation",
              },
              {
                text: "Application Modernization",
                link: "/services/application-modernization",
              },
              {
                text: "RPA Implementation",
                link: "/services/rpa-implementation",
              },
              {
                text: "BlockChain",
                link: "/services/blockchain",
              },
            ],
          },
          {
            title: "Product Consulting",
            url: "/services/product-consulting",
            descr: "",
            links: [
              {
                text: "Product Strategy",
                link: "/services/product-strategy",
              },
              {
                text: "User Experience (UX) Design",
                link: "/services/user-experience-design",
              },
              {
                text: "Product Requirements Definition",
                link: "/services/product-requirements-definition",
              },
            ],
          },
          {
            title: "AI Consulting",
            url: "/services/artificial-intelligence-consulting",
            descr: "",
            links: [
              {
                text: "Strategy and Planning",
                link: "/services/strategy-and-planning",
              },
              {
                text: "AI Solution Evaluation",
                link: "/services/artificial-intelligence-solution-evaluation",
              },
              {
                text: "Data Analysis and Modeling",
                link: "/services/data-analysis-and-modeling",
              },
            ],
          },
          {
            title: "DevOps Consulting",
            url: "/services/devOps-consulting",
            descr: "",
            links: [
              {
                text: "DevOps Assessment and Strategy",
                link: "/services/devops-assessment-and-strategy",
              },
              {
                text: "Cloud Adoption and Migration",
                link: "/services/cloud-adoption-and-migration",
              },
              {
                text: "Continuous Integration and Continuous Delivery (CI/CD) Pipeline",
                link: "/services/continuous-integration-and-continuous-delivery-pipeline",
              },
            ],
          },
        ],
      },
    ],
  },
];
