import { menuDropdownProps } from '@/utils/types';
import { Col, Container, Nav, NavDropdown, Row, Tab } from 'react-bootstrap';
import TabList from './TabList';
import TabPaneList from './TabPaneList';
import { useState } from 'react';

interface MenuDropdownProps {
  data: any;
  menuType?: string;
  navbar?: boolean;
  expanded: boolean;
  setExpanded: Function;
}
const MenuDropdown = ({ data, expanded, setExpanded , menuType, navbar}: MenuDropdownProps) => {

  const [expandedDropdown, setExpandedDropdown] = useState(false)

  const handleDropdownToggle = (value: boolean) => {
    // e.isPropagationStopped()
    setExpandedDropdown(value);
  };

  return (
    <NavDropdown
      title={<span className={menuType === 'transparent' && !navbar ? 'header-color-white' : ''}>{data?.heading}</span>}
      renderMenuOnMount
      onToggle={(show) => { handleDropdownToggle(false); }}
      className={menuType === 'transparent'&& !navbar ? 'drop-toggle-icon':''}

    >
      <Tab.Container defaultActiveKey='0'>
        <div className={expandedDropdown ? 'd-none' : 'menu-dropdown animation slideDownIn'}>
          <Container fluid>
            <Row>
              <Col lg={3} xl={2}>
                <Nav variant='pills' className='flex-column'>
                  {data.dropdown.map(
                    ({ title }: menuDropdownProps, index: number) => (
                      <TabList
                        text={title}
                        tab={index.toString()}
                        key={index.toString()}
                      />
                    )
                  )}
                </Nav>
              </Col>
              <Col lg={9} xl={10} className='mt-4 mt-lg-0'>
                <Tab.Content>
                  {data.dropdown.map(
                    ({ content }: menuDropdownProps, index: number) => (
                      <TabPaneList
                        data={content}
                        tab={index.toString()}
                        key={index.toString()}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        handleDropdownToggle={handleDropdownToggle}
                      />
                    )
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Container>
        </div>
      </Tab.Container>
    </NavDropdown>
  );
};

export default MenuDropdown;
