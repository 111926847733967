import React from 'react';
import { Col } from 'react-bootstrap';
import TabLinks from './TabLinks';
import Link from 'next/link';
import Image from 'next/image';
import Router, { useRouter } from 'next/router';
import useWindowSize from '@/hooks/useWindowSize';

interface TabPaneContentProps {
  data: any;
  expanded: boolean;
  setExpanded: Function;
  handleDropdownToggle: Function;
}

const splitList = (links: any[], type = 1) => {
  const sort =
    type === 1
      ? links.filter((item: any) => !item?.single)
      : links.filter((item: any) => item?.single === true);

  return sort;
};



const TabPaneContent = ({
  data: { url, title, descr, links },
  expanded,
  setExpanded,
  handleDropdownToggle
}: TabPaneContentProps) => {
  const primaryLinks = splitList(links);
  const secondaryLinks = splitList(links, 2);
  const router = useRouter();
  const [width] = useWindowSize();

  const redirectToPage = (e: any, url: string) => {
    if (e?.ctrlKey)
      window.open(url, '_blank')
    else
      router.push(url)
    if (width < 767)
      setExpanded(!expanded)
    else {
      handleDropdownToggle(e, true)
    }
  }

  return (
    <Col md={6} lg>
      <h5>
        <a className='cursor-pointer' onClick={(e) => redirectToPage(e, url)}>

          {/* <Link href={url}> */}
          {title}{' '}
          <Image
            src='/assets/icons/blue.svg'
            width={61}
            height={53}
            priority={true}
            alt={title}
            style={{ objectFit: 'cover' }}
            className='img-fluid'
          />
          {/* </Link> */}
        </a>
      </h5>
      <p>{descr}</p>
      <ul>
        {primaryLinks.map((link: any, index: number) => (
          <TabLinks data={link} key={`tab-link-${index}`} expanded={expanded} setExpanded={setExpanded} handleDropdownToggle={handleDropdownToggle} />
        ))}
        <li>
          {secondaryLinks.map((link: any, index: number) => (
            <React.Fragment key={index.toString()}>
              {/* <button onClick={() => redirectToOtherPage(link)}>{link.text}</button> */}
              <Link href={link.link}>{link.text}</Link>
              {index !== secondaryLinks.length - 1 && (
                <span className='mx-3'>|</span>
              )}
            </React.Fragment>
          ))}
        </li>
      </ul>
    </Col>
  );
};

export default React.memo(TabPaneContent);
