import useWindowSize from '@/hooks/useWindowSize';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

interface TabLinksProps {
  data: any;
  expanded: boolean;
  setExpanded: Function;
  handleDropdownToggle: Function;
}

const TabLinks = ({ data, expanded, setExpanded, handleDropdownToggle }: TabLinksProps) => {
  const router = useRouter();
  const [width] = useWindowSize();



  const redirectToPage = (e: any,data:any) => {
    e.preventDefault();
    if (e?.ctrlKey)
      window?.open(data?.link, '_blank')
    else
      router.push(data?.link)
      // const sectionElement = document.getElementById("section1");
      // if (sectionElement) {
      //   sectionElement.scrollIntoView({ behavior: 'smooth' });
      // }
    if (width < 767)
      setExpanded(!expanded)
    else {
      handleDropdownToggle(e, true)
    }
  
  }

  
  return (
    <li className='cursor-pointer'>
      {/* <Link href={data.link}>{data.text}</Link> */}
      <a  onClick={(e)=>redirectToPage(e,data)}>{data.text}</a>
    </li>
  );
};

export default React.memo(TabLinks);
